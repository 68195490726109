@use '../../styles/yr' as *;

.map-weather-toolbar {
  background-color: var(--color-button-new-buttongroup-bg-rest);
  box-shadow: $theme-box-shadow-elevated;
  border-radius: size(1);
  margin: 0 size(1) size(1) size(1);

  @include mq-gte($mq-map-desktop) {
    width: fit-content;
    margin: 0 auto;
  }
}

.map-weather-toolbar__button {
  min-width: fit-content;
  padding: 0;
  @include mq-lt($mq-map-desktop) {
    min-width: unset;
  }

  // OVERRIDE(tl): We want to remove the padding from the Button
  // component here so that we can see the text as long as possible.
  .button__content-text {
    @include mq-lt($mq-map-desktop) {
      margin-left: rem(-6px);
      margin-right: rem(-6px);
    }
  }
}
